// api.js

import axios from "../../../axiosinstance";
export const forgotPassword = async (userid) => {

  try {
    const response = await axios.get(`otps-service/api/Otps/v2/forget/password/V2?otpOn=SMS&resourceCode=JAINA&userid=${userid}&type=USERNAME&msgCode=xyz`);
    return response.data;
  } catch (error) {
    throw error;
  }
  };
  
 
  export const GernateNewPassword = async (formData) => {
    
    try {
      const response = await axios.post(`login-service-mongo/api/loginservice/v2/ForgetPasswordV2`, formData);
      return response.data;
    } catch (error) {
      throw error;
    }
    };

  
  
//forgot passwor otp 
export const conformforgotOtp = async (userid, otp) => {
  

  
  try {
    const response = await axios.post(`otps-service/api/Otps/v2/confirmV3?otp=${otp}&userid=${userid}&msgType=USERNAME`);
    return response.data;
  } catch (error) {
    throw error;
  }
  };
  
