import React, { useState } from "react";
import { useFormik } from "formik";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import CoverLayout from "layouts/authentication/components/CoverLayout";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { useLocation } from "react-router-dom";
import swal from "sweetalert";
import { MoonLoader } from "react-spinners";
// Images
import bgImage from "assets/images/bg-pricing.jpg";

import { useNavigate } from "react-router-dom";
import { conformforgotOtp, forgotPassword } from "./service/Forgotpassword";

const initialValues = {
  mobileNumber: "",
  otp: "",
};

const validationSchema = Yup.object({
  mobileNumber: Yup.string()
    .required("Mobile number is required")
    .matches(/^\d{10}$/, "Mobile number must be 10 digits"),
  otp: Yup.string().when(["mobileNumber", "showOTPInput"], {
    is: (mobileNumber, showOTPInput) => showOTPInput && !!mobileNumber,
    then: Yup.string()
      .required("OTP is required")
      .matches(/^\d{6}$/, "OTP must be 6 digits"),
  }),
});

const ForgotPassword = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false); // Loader state
  const [showOTPInput, setShowOTPInput] = useState(false);

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
    setSubmitting,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setIsLoading(true);
      if (!showOTPInput && values.mobileNumber.length === 10) {
        try {
          // Call the API function with form values

          const responseData = await forgotPassword(values.mobileNumber);

          // Handle the response data as needed
          console.log("API Response:", responseData);
          setShowOTPInput(true);
          setIsLoading(false);
          // Reset the form after successful submission
        } catch (error) {
          // Handle any API errors or other errors
          swal(
            "Oops!",
            error?.response?.data?.aceErrors?.[0]?.errorMessage ||"Something wents wrong",
            "warning"
          );
          setIsLoading(false);
        }
      } else if (showOTPInput) {
        setIsLoading(true);

        try {
          // // Call the API function with form values

          const responseData = await conformforgotOtp(
            values.mobileNumber,
            values.otp
          );

          // Handle the response data as needed
          console.log("API Response:", responseData);
          navigate("/app/NewMpin/new-mpin", { state: { responseData } });
          setShowOTPInput(true);
          setIsLoading(false);
          // Reset the form after successful submission
        } catch (error) {
          // Handle any API errors or other errors
          swal(
            "Oops!",
            error?.response?.data?.aceErrors[0]?.errorMessage || 'Something wents wrong',
            "warning"
          );
          setIsLoading(false);
        }
      }
      setSubmitting(false);
    },
  });

  return (
    <CoverLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-1}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={2}>
            Forgot Password
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            {showOTPInput ? "Enter OTP" : "Enter your Mobile Number"}
          </MDTypography>
        </MDBox>

        <form onSubmit={handleSubmit}>
          <MDBox pb={3} px={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} mt={2}>
                <MDInput
                  fullWidth
                  name="mobileNumber"
                  id="mobileNumber"
                  type="text"
                  label="Mobile Number"
                  autoComplete="off"
                  value={values.mobileNumber}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {errors.mobileNumber && touched.mobileNumber && (
                  <p
                    style={{
                      fontWeight: "400",
                      color: "red",
                      fontSize: "12px",
                    }}
                  >
                    {errors.mobileNumber}
                  </p>
                )}
              </Grid>
              {showOTPInput && (
                <Grid item xs={12}>
                  <MDInput
                    fullWidth
                    name="otp"
                    id="otp"
                    type="text"
                    label="OTP"
                    autoComplete="off"
                    value={values.otp}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  {errors.otp && touched.otp && (
                    <p
                      style={{
                        fontWeight: "400",
                        color: "red",
                        fontSize: "12px",
                      }}
                    >
                      {errors.otp}
                    </p>
                  )}
                </Grid>
              )}
              <Grid container spacing={3} mt={3}>
                <Grid item xs={12} sm={8}>
                  {/* Any additional components */}
                </Grid>
                <Grid item xs={12} sm={4}>
                  <MDButton
                    variant="gradient"
                    color="dark"
                    size="small"
                    type="submit"
                    disabled={isLoading}
                    style={{ position: "absolute", right: "8%" }}
                  >
                    {isLoading ? (
                      <MoonLoader color="#f2fefa" size={16} />
                    ) : // Correct placement of ternary operator
                    showOTPInput ? (
                      "Submit OTP"
                    ) : (
                      "Submit"
                    )}
                  </MDButton>
                </Grid>
              </Grid>
            </Grid>
          </MDBox>
        </form>
        <MDBox mt={3} mb={1} textAlign="center">
          <MDTypography variant="button" color="text">
           <></>
            <MDTypography
              component={Link}
              to="/authentication/sign-in/basic"
              variant="button"
              color="info"
              fontWeight="medium"
              textGradient
            >
           <></>
            </MDTypography>
          </MDTypography>
        </MDBox>
      </Card>
    </CoverLayout>
  );
};

export default ForgotPassword;
