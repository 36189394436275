import axios from "../../axiosinstance"

export const logIn = (data) => async (dispatch) => {
    dispatch({ type: "LOGIN_PENDING", payload: "" })
    try {

        const resp = await axios.post('login-service-mongo/api/loginservice/v2/loginV2', data)
        // const resp = await axios.post(`otps-service/api/Otps/v2/confirmV2?otp=${data.otp}&userid=${data.mobileNumber}&msgType=DIRECT`)

        dispatch({ type: "LOGIN_FULFILLED", payload: resp.data })
       
    } catch (error) {
        dispatch({ type: "LOGIN_REJECTED", payload: error.response?.data })
    }
   
}
